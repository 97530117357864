import { useFrame, useThree } from "@react-three/fiber";
import { WebGLRenderer } from "three";
import { Physics, usePlane, useSphere, useBox } from "@react-three/cannon";
import { SSAO, Bloom, EffectComposer } from "@react-three/postprocessing";
import { softShadows } from "@react-three/drei";
import { useRef } from "react";

// softShadows();
export default function Balls(virtualScene) {
  const { gl } = useThree();
  const Refcomposer = useRef();
  useFrame(() => {
    gl.autoClear = false;
    Refcomposer.current.render();
    gl.setRenderTarget(null);
  }, 2);

  return (
    <>
      <>
        <color attach="background" args={["#ffdd41"]} />
        <fog attach="fog" args={["red", 81, 92]} />
        <ambientLight intensity={1} />
        <directionalLight
          intensity={2}
          position={[10, 10, 5]}
          castShadow
          shadow-mapSize={[1024, 1024]}
          shadow-camera-left={-50}
          shadow-camera-right={50}
          shadow-camera-top={50}
          shadow-camera-bottom={-50}
        />
        <directionalLight
          intensity={1.5}
          position={[0, 10, 0]}
          castShadow
          shadow-mapSize={[1024, 1024]}
          shadow-camera-left={-50}
          shadow-camera-right={50}
          shadow-camera-top={50}
          shadow-camera-bottom={-50}
        />
        <Physics
          gravity={[0, -100, 0]}
          defaultContactMaterial={{ restitution: 0.5 }}
        >
          <group position={[0, 0, 10]}>
            <Borders />
            <InstancedSpheres />
            <Mouse />
          </group>
        </Physics>
        <EffectComposer
          ref={Refcomposer}
          scene={virtualScene.virtualScene}
          camera={virtualScene.virtualCam.current}
          multisampling={0}
        >
          <SSAO
            samples={11}
            radius={30}
            intensity={20}
            luminanceInfluence={0}
            color="red"
          />
          <SSAO
            samples={21}
            radius={7}
            intensity={20}
            luminanceInfluence={0.6}
            color="red"
          />
          <Bloom
            intensity={1}
            kernelSize={2}
            luminanceThreshold={0.8}
            luminanceSmoothing={0.0}
          />
        </EffectComposer>
      </>
    </>
  );
}

function InstancedSpheres({ count = 100 }) {
  const { viewport } = useThree();
  const [ref] = useSphere((index) => ({
    mass: 100,
    position: [index - Math.random() * 8, viewport.height / 2, 0],
    args: [2.1],
  }));
  return (
    <instancedMesh
      ref={ref}
      castShadow
      receiveShadow
      args={[null, null, count]}
    >
      <sphereBufferGeometry ref={ref} args={[2, 32, 32]} />
      <meshStandardMaterial color="#ff7b00" />
    </instancedMesh>
  );
}

function Borders(size) {
  const { viewport } = useThree();
  return (
    <>
      <Plane
        position={[0, -viewport.height / 4 + 3, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <Plane position={[-15, 0, 0]} rotation={[0, Math.PI / 2, 0]} />
      <Plane position={[15, 0, 0]} rotation={[0, -Math.PI / 2, 0]} />
      {/* <mesh
        depth={false}
        position={[0, -viewport.height / 8 + 2, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        receiveShadow
      >
        <planeBufferGeometry
          attach="geometry"
          args={[viewport.width, viewport.height]}
        />
        <meshLambertMaterial attach="material" color={"yellow"} />
      </mesh> */}
      <Plane position={[0, 0, 0]} rotation={[0, 0, 0]} />
      <Plane position={[0, 0, 10]} rotation={[0, -Math.PI, 0]} />
    </>
  );
}

function Plane({ color, ...props }) {
  usePlane(() => ({ ...props }));
  return null;
}

function Mouse(size) {
  const { viewport } = useThree();
  const [ref, api] = useSphere(() => ({ args: [8.1, 8.1, 12] }));
  useFrame((state) =>
    api.position.set(
      state.mouse.x * (viewport.width / 2),
      state.mouse.y < 0.5
        ? state.mouse.y * (viewport.height / 2)
        : 0.5 * (viewport.height / 2),
      7
    )
  );
  return (
    <mesh receiveShadow castShadow ref={ref}>
      <sphereGeometry args={[8]} />
      <meshLambertMaterial color="#ff7b00" />
    </mesh>
  );
}
