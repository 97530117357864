import {
  React,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Canvas,
  extend,
  useFrame,
  useThree,
  createPortal,
} from "@react-three/fiber";
import {
  PerspectiveCamera,
  OrthographicCamera,
  useCamera,
  Html,
  TorusKnot,
  Plane,
} from "@react-three/drei";

export default function SpinningThing() {
  const mesh = useRef();
  useFrame(
    () =>
      (mesh.current.rotation.x =
        mesh.current.rotation.y =
        mesh.current.rotation.z +=
          0.01)
  );
  return (
    <mesh scale={5}>
      <TorusKnot position={[0, 0, 0]} ref={mesh} args={[1, 0.4, 100, 64]}>
        <meshNormalMaterial attach="material" />
      </TorusKnot>
    </mesh>
  );
}
