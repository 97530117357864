import {
  React,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Canvas,
  extend,
  useFrame,
  useThree,
  createPortal,
} from "@react-three/fiber";
import { Scene, Matrix4 } from "three";
import { EffectComposer, Outline } from "@react-three/postprocessing";
import {
  PerspectiveCamera,
  OrthographicCamera,
  useCamera,
  Html,
  TorusKnot,
  Plane,
} from "@react-three/drei";
import CameraControls from "camera-controls";
import * as THREE from "three";
import { SSAO, Bloom } from "@react-three/postprocessing";

import Balls from "./Components/Balls";
import SpinningThing from "./Components/SpinningThing";
import WelcomeApp from "./Components/Welcome/WelcomeApp";
import CountrySphere from "./Components/CountrySphere";
import CubeApp from "./Components/Cubes";
import GrassApp from "./Components/Grass/GrassApp";
CameraControls.install({ THREE });

extend({ CameraControls });
const AppsArray = [
  { Component: WelcomeApp, title: "Welcome", desc: "Click Me!" },
  { Component: SpinningThing, title: "Torus Knot", desc: "" },
  { Component: Balls, title: "Collision", desc: "Handpose Physics" },
  { Component: GrassApp, title: "Grass", desc: "Grass Shaders Demo" },
  {
    Component: CubeApp,
    title: "Cubes",
    desc: "Instanced Raycast Demo",
  },
  {
    Component: CountrySphere,
    title: "Country Sphere",
    desc: "Restcountries api with search Demo ",
  },
];

const CamZoomPos = 40;
const CamDefaultPos = 100;
const PlaneDefaultPos = 1.01;
const CamPos = [
  [CamZoomPos, 0, 0],
  [-CamZoomPos, 0, 0],
  [0, CamZoomPos, 0],
  [0, -CamZoomPos, 0],
  [0, 0, CamZoomPos],
  [0, 0, -CamZoomPos],
  [CamDefaultPos, 0, 0],
];

const PlanePos = [
  [PlaneDefaultPos, 0, 0],
  [-PlaneDefaultPos, 0, 0],
  [0, PlaneDefaultPos, 0],
  [0, -PlaneDefaultPos, 0],
  [0, 0, PlaneDefaultPos],
  [0, 0, -PlaneDefaultPos],
  [PlaneDefaultPos, 0, 0],
];

const PlaneRotation = [
  [0, Math.PI / 2, 0],
  [0, -Math.PI / 2, 0],
  [-Math.PI / 2, 0, Math.PI / 2],
  [Math.PI / 2, 0, -Math.PI / 2],
  [0, 0, 0],
  [0, -Math.PI, 0],
  [0, Math.PI / 2, 1],
];

const CamDefault = [
  [CamDefaultPos, 0, 0],
  [-CamDefaultPos, 0, 0],
  [0, CamDefaultPos, 0],
  [0, -CamDefaultPos, 0],
  [0, 0, CamDefaultPos],
  [0, 0, -CamDefaultPos],
  [CamDefaultPos, 0, 0],
];

const CamRotation = [
  [0, Math.PI / 2, 0],
  [0, -Math.PI / 2, 0],
  [-Math.PI / 2, 0, 0],
  [-Math.PI / 2, 0, 0],
  [0, 0, Math.PI / 2],
  [0, Math.PI, -Math.PI / 2],
  [0, Math.PI / 2, 0],
];

function Controls({ zoom, faceIndex = 6, zoomed, handleZoomFinish }) {
  const { gl, scene, camera } = useThree();
  const controls = useMemo(() => new CameraControls(camera, gl.domElement), []);
  controls.mouseButtons.right = CameraControls.ACTION.NONE;
  controls.mouseButtons.middle = CameraControls.ACTION.NONE;
  controls.mouseButtons.wheel = CameraControls.ACTION.NONE;
  controls.mouseButtons.left = CameraControls.ACTION.NONE;
  controls.touches.one = CameraControls.ACTION.NONE;
  controls.touches.two = CameraControls.ACTION.NONE;
  controls.touches.three = CameraControls.ACTION.NONE;
  if (!zoomed) {
    controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
    controls.touches.one = CameraControls.ACTION.ROTATE;
  }

  // if(scene.children[0])
  // {
  //   console.log(window.innerWidth)
  // }

  async function complexTransitionIn(faceIndex) {
    controls.mouseButtons.left = CameraControls.ACTION.NONE;
    await controls.setLookAt(
      CamPos[faceIndex][0],
      CamPos[faceIndex][1],
      CamPos[faceIndex][2],
      CamRotation[faceIndex][0],
      CamRotation[faceIndex][1],
      CamRotation[faceIndex][2],
      true
    );
  }

  async function complexTransitionOut(faceIndex) {
    controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
    await controls.setLookAt(
      CamDefault[faceIndex][0],
      CamDefault[faceIndex][1],
      CamDefault[faceIndex][2],
      CamRotation[faceIndex][0],
      CamRotation[faceIndex][1],
      CamRotation[faceIndex][2],
      true
    );
  }

  if (zoom) {
    if (zoomed) {
      complexTransitionIn(faceIndex).then(() => {
        controls.setLookAt(
          CamDefault[faceIndex][0],
          CamDefault[faceIndex][1],
          CamDefault[faceIndex][2],
          CamRotation[faceIndex][0],
          CamRotation[faceIndex][1],
          CamRotation[faceIndex][2],
          false
        );
        handleZoomFinish(false, zoomed);
      });
    } else {
      controls.setLookAt(
        CamPos[faceIndex][0],
        CamPos[faceIndex][1],
        CamPos[faceIndex][2],
        CamRotation[faceIndex][0],
        CamRotation[faceIndex][1],
        CamRotation[faceIndex][2],
        false
      );
      handleZoomFinish(false, zoomed);
      complexTransitionOut(faceIndex).then(() => {});
    }
  }
  return useFrame((state, delta) => {
    return controls.update(delta);
  });
}
function Viewcube({ zoomToView, clicked }) {
  const { gl, scene, camera, size } = useThree();
  const virtualCubeScene = useMemo(() => new Scene(), []);
  const virtualCubeCam = useRef();
  const ref = useRef();
  const [hover, setHover] = useState(null);
  const matrix = new Matrix4();
  const [focus, setFocus] = useState(6);

  function HandleClick(face) {
    if (clicked) {
      zoomToView(true, face, false, false);
    } else {
      zoomToView(true, face, true, true);
      setFocus(face);
    }
  }

  useFrame(() => {
    matrix.copy(camera.matrix).invert();
    ref.current.quaternion.setFromRotationMatrix(matrix);
    gl.autoClear = false;
    gl.render(virtualCubeScene, virtualCubeCam.current);
    gl.setRenderTarget(null);
  }, 3);

  return createPortal(
    <>
      <OrthographicCamera
        ref={virtualCubeCam}
        makeDefault={false}
        position={[0, 0, 100]}
      />
      <mesh
        ref={ref}
        raycast={useCamera(virtualCubeCam)}
        position={[size.width / 2 - 80, size.height / 2 - 80, 0]}
        onPointerOut={(e) => setHover(null)}
        onPointerMove={(e) => setHover(Math.floor(e.faceIndex / 2))}
        onClick={(e) => HandleClick(Math.floor(e.faceIndex / 2))}
        scale={30}
      >
        {[...Array(6)].map((_, index) => (
          <meshLambertMaterial
            attachArray="material"
            key={"ViewCube" + index}
            color={
              clicked
                ? hover === index
                  ? "#f44336"
                  : "#37d67a"
                : hover === index
                ? "#37d67a"
                : "white"
            }
          />
        ))}
        <boxGeometry args={[2, 2, 2]} />
      </mesh>
      <ambientLight intensity={0.5} />
      <pointLight position={[100, 100, 100]} intensity={0.5} />
    </>,
    virtualCubeScene
  );
}

function Box({ props, zoomToView, clicked, focus, windowSize, zoom, zoomed }) {
  // This reference gives us direct access to the THREE.Mesh object
  const mesh = useRef();
  const { viewport } = useThree();
  const [viewSize, setSize] = useState();

  const { gl, scene, camera, size } = useThree();
  useFrame(() => {
    gl.render(scene, camera);
  }, 0);

  useEffect(() => {
    if (!clicked) {
      setViewSize(0.25);
    }
    // console.log("box", clicked, zoom)
    if (clicked && !zoom) {
      setViewSize(1);
    }
  }, [windowSize, clicked, zoom]);

  function setViewSize(factor) {
    // console.log("SET:",factor,viewport.height,viewport.width)
    if (windowSize.height > windowSize.width) {
      setSize(viewport.width * factor);
    } else {
      setSize(viewport.height * factor);
    }
  }
  function HandleClick(face) {
    if (!clicked) {
      zoomToView(true, face, true, true);
    }
  }
  const [hover, setHover] = useState(false);
  return (
    <mesh
      {...props}
      ref={mesh}
      scale={viewSize}
      onPointerOut={(e) => setHover(null)}
      onPointerMove={(e) => setHover(Math.floor(e.faceIndex / 2))}
      onClick={(e) => HandleClick(Math.floor(e.faceIndex / 2))}
    >
      <boxGeometry args={[2, 2, 2]} />
      {AppsArray.map((AppI, index) => (
        <>
          <meshBasicMaterial
            attachArray="material"
            key={index}
            color={
              clicked ? "lightblue" : hover === index ? "#37d67a" : "white"
            }
          />
          {clicked && zoomed && !zoom ? (
            <ThreeApp
              attachArray="material"
              index={index}
              focus={focus}
              mesh={mesh}
              size={viewSize}
              zoom={zoom}
              zoomed={zoomed}
            />
          ) : (
            <Html
              position={PlanePos[index]}
              rotation={PlaneRotation[index]}
              transform
              occlude
              distanceFactor={1}
            >
              {/* <head>
                <title>{AppI.title}</title>
              </head>
              <body>
                <h1>{AppI.desc}</h1>
                <p>This is a paragraph.</p>
              </body> */}
              <div className="h-96 flex flex-col items-center">
                <div className="flex-grow p-2 max-w-lg "></div>

                <div className="flex-grow-0 p-2">
                  <h1 className="text-6xl text-center">{AppI.title}</h1>
                </div>

                <div className="flex-grow p-2 max-w-lg ">
                  <p className="text-xl text-center">{AppI.desc}</p>
                </div>
              </div>
            </Html>
          )}
        </>
      ))}
    </mesh>
  );
}

function ThreeApp({ index, focus, mesh, clicked, size, zoom }) {
  const { gl, scene, camera } = useThree();
  const screen = useRef();
  const virtualScene = useMemo(() => new Scene(), []);
  const virtualCam = useRef();
  useFrame(() => {
    if (index === focus) {
      gl.clearDepth();
      gl.autoClear = false;
      gl.render(virtualScene, virtualCam.current);
    }
  }, 1);
  return createPortal(
    <>
      <>
        {index === focus ? (
          <>
            <>
              <PerspectiveCamera
                ref={virtualCam}
                makeDefault={false}
                position={[0, 0, 100]}
                fov={50}
                near={20}
                far={150}
              />
              {AppsArray.map((AppI, index) => {
                if (index === focus)
                  return (
                    <AppI.Component
                      size={size}
                      virtualCam={virtualCam}
                      virtualScene={virtualScene}
                      key={index}
                    />
                  );
              })}
            </>
          </>
        ) : (
          <></>
        )}
      </>
    </>,
    virtualScene
  );
}

export default function App() {
  const [zoom, setZoom] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const [focus, setFocus] = useState(6);
  const [clicked, setClicked] = useState(false);

  function HandleZoom(zoom, focusRef, zoomed, clicked) {
    setFocus(focusRef);
    setZoomed(zoomed);
    setZoom(zoom);
    setClicked(clicked);
  }
  console.log(zoomed, clicked, zoom);
  function HandleZoomFinish(zoom, zoomed) {
    setZoomed(zoomed);
    setZoom(zoom);
  }

  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="w-screen h-screen">
      <Canvas
        shadowMap
        shadows
        gl={{ alpha: false, antialias: false }}
        camera={{ makeDefault: false, position: [100, 0, 0], fov: 50 }}
      >
        <>
          <color attach="background" args={["#ffdd41"]} />
          <Box
            position={[0, 0, 0]}
            clicked={clicked}
            windowSize={windowSize}
            focus={focus}
            zoom={zoom}
            zoomed={zoomed}
            zoomToView={(zoom, focusRef, zoomed, clicked) =>
              HandleZoom(zoom, focusRef, zoomed, clicked)
            }
          />
          <Viewcube
            clicked={clicked}
            zoomToView={(zoom, focusRef, zoomed, clicked) =>
              HandleZoom(zoom, focusRef, zoomed, clicked)
            }
          />
          {/* <ambientLight intensity={1} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <pointLight position={[-10, -10, -10]} /> */}

          <Controls
            zoom={zoom}
            faceIndex={focus}
            zoomed={zoomed}
            handleZoomFinish={(zoom, zoomed) => HandleZoomFinish(zoom, zoomed)}
          />
        </>
      </Canvas>
    </div>
  );
}
