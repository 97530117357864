import * as THREE from "three";
import ReactDOM from "react-dom";
import React, { useRef, useMemo, useState, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { SSAO, Bloom, EffectComposer } from "@react-three/postprocessing";
import {
  Instances,
  Instance,
  OrbitControls,
  useCamera,
} from "@react-three/drei";
import { Suspense } from "react";
import { useControls } from "leva";

const color = new THREE.Color();

function Boxes({ range, virtualScene, virtualCam }) {
  const data = Array.from({ length: range * range * range }, (box, index) => ({
    color: "#ff7b00",
    scale: 1,
    position: [
      range / 2 - Math.floor(index / (range * range)),
      range / 2 - Math.floor((index % (range * range)) / range),
      range / 2 - Math.floor(index % range),
    ],
  }));
  return (
    <Instances range={1000} raycast={useCamera(virtualCam)}>
      <boxBufferGeometry args={[0.5, 0.5, 0.5]} />
      <meshStandardMaterial color="#ff7b00" />
      {data.map((props, i) => (
        <Box key={i} virtualScene={virtualScene} {...props} />
      ))}
    </Instances>
  );
}

function Box({ virtualScene, ...props }) {
  const ref = useRef();
  const [hovered, setHover] = useState(false);
  useFrame((state) => {
    ref.current.scale.x =
      ref.current.scale.y =
      ref.current.scale.z =
        THREE.MathUtils.lerp(ref.current.scale.z, hovered ? 3 : 1, 0.1);
    ref.current.color.lerp(
      color.set(hovered ? "white" : "#ff7b00"),
      hovered ? 1 : 0.1
    );
  }, 1);
  return (
    <group {...props}>
      <Instance
        ref={ref}
        onPointerOver={(e) => (e.stopPropagation(), setHover(true))}
        onPointerOut={() => setHover(false)}
      />
    </group>
  );
}

export default function CubeApp({ virtualScene, virtualCam }) {
  const { range } = useControls({
    range: { value: 5, min: 0, max: 10, step: 1 },
  });
  const { gl } = useThree();
  const Refcomposer = useRef();
  useFrame(() => {
    gl.autoClear = false;
    Refcomposer.current.render();
    gl.setRenderTarget(null);
  }, 2);
  return (
    <>
      <color attach="background" args={["#ffdd41"]} />
      <group scale={3}>
        <ambientLight intensity={1} />
        <directionalLight
          intensity={1.5}
          position={[10, 10, 5]}
          castShadow
          shadow-mapSize={[1024, 1024]}
          shadow-camera-left={-50}
          shadow-camera-right={50}
          shadow-camera-top={50}
          shadow-camera-bottom={-50}
        />

        <pointLight position={[150, 150, 150]} intensity={0.55} />
        <Suspense fallback={null}>
          <Boxes
            range={range}
            virtualScene={virtualScene}
            virtualCam={virtualCam}
          />
          <OrbitControls camera={virtualCam.current} />
        </Suspense>
      </group>
      <EffectComposer
        ref={Refcomposer}
        scene={virtualScene}
        camera={virtualCam.current}
      >
        <Bloom
          intensity={1}
          kernelSize={2}
          luminanceThreshold={0.8}
          luminanceSmoothing={0.0}
        />
      </EffectComposer>
    </>
  );
}
