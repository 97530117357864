import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Sky, OrbitControls } from "@react-three/drei";
import Grass from "./Grass";

export default function GrassApp(virtualScene) {
  return (
    <>
      <Sky azimuth={1} inclination={0.6} distance={1000} />
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Suspense fallback={null}>
        <Grass/>
      </Suspense>
      <OrbitControls
        camera={virtualScene.virtualCam.current}
        minPolarAngle={Math.PI / 5}
        maxPolarAngle={Math.PI / 2.5}
      />
    </>
  );
}
