import React, { Suspense, useRef, useEffect, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Stars, useCamera } from "@react-three/drei";
import { useGLTF, useTexture, useAnimations } from "@react-three/drei";
import { Html, useProgress } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";

function Model({ virtualCam, ...props }) {
  const group = useRef();
  const texture = useTexture("popcat/cat3_Material_004_BaseColor.png");
  const { scene, animations } = useGLTF("popcat/cat.gltf");
  const { ref, actions, names } = useAnimations(animations, scene);
  function playAnimation() {
    actions[names[0]].reset().setLoop(1).play();
  }

  return (
    <>
      <primitive
        {...props}
        object={scene}
        onPointerOver={() => playAnimation()}
        onPointerOut={() => actions[names[0]].reset().stop()}
      />
      <Html center position={[0, 26, 0]}>
        <h1 className="text-2xl text-center w-96">Hi, I'm Tiger</h1>
        <h1 className="text-sm text-center w-96">Hover over me!</h1>
      </Html>
    </>
  );
}

export default function WelcomeApp({ virtualCam }) {
  return (
    <>
      <ambientLight intensity={1} />
      <Suspense fallback={null}>
        <Model
          position={[0, 10, 0]}
          rotation={[0.5, 0, 0]}
          virtualCam={virtualCam}
        />
        <Html center position={[0, -10, 0]}>
          <div className="flex flex-col items-center">
            <div className="flex-grow-0 p-2 ">
              <h1 className="text-2xl text-center w-96 ">
                Welcome to Three Cube
              </h1>
            </div>

            <div className="h-40 p-2">
              <p className="text-m text-center">
                This project is an experiment at combining React, ThreeJS and
                ML5.JS to create a new viewing experience.
              </p>
              <p className="text-m text-center">
                Please direct any inquires at:
                <a href="mailto:Dev@tsun.io"> dev@tsun.io</a>
              </p>
            </div>
            <div className="flex-grow p-2">
              <h1 className="text-xl w-screen text-center">
                Please turn on Hardware Acceleration for a better experience.
              </h1>
            </div>
            <div className="flex-grow p-2">
              <h1 className="text-xl w-screen text-center">
                Click the green square on the top right of the screen to exit
                and begin exploring.
              </h1>
            </div>
          </div>
        </Html>
      </Suspense>
    </>
  );
}
