import * as THREE from "three";
import { useRef, useState, useMemo, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { forceResize } from "react-three-fiber";
import { Text, Image, OrbitControls, Html, useCamera } from "@react-three/drei";
import axios from "axios";
import React, { Suspense } from "react";

function Country({ virtualScene, virtualCam, country, position, ...props }) {
  const { gl } = useThree();
  const fontProps = {
    fontSize: 1,
    letterSpacing: 0.02,
    lineHeight: 1,
    anchorX: "center",
    anchorY: "middle",
  };
  const ref = useRef();
  const meshRef = useRef();
  // console.log(position);
  const [hovered, setHovered] = useState(false);
  const [hidden, setVisible] = useState(false);
  useFrame(() => {
    ref.current.quaternion.copy(virtualCam.current.quaternion);
    meshRef.current.quaternion.copy(virtualCam.current.quaternion);
    ref.current.scale.x =
      ref.current.scale.y =
      ref.current.scale.z =
      meshRef.current.scale.x =
      meshRef.current.scale.y =
      meshRef.current.scale.z =
        THREE.MathUtils.lerp(
          meshRef.current.scale.z,
          hovered ? 2.5 : 1.25,
          0.1
        );

    // if (hovered) {

    // }
  }, 1);
  return (
    <group position={position}>
      <mesh
        {...props}
        scale={1.25}
        ref={meshRef}
        raycast={useCamera(virtualCam)}
        onPointerEnter={() => {
          setHovered(true);
        }}
        onPointerOut={() => {
          setHovered(false);
        }}
        onPointerDown={() => console.log({ country })}
      >
        <boxGeometry args={[2, 2, 2]} />
        <meshStandardMaterial color={hovered ? "lightgreen" : "orange"} />
      </mesh>
      <group ref={ref}>
        <Text
          position={[0, -2, 0]}
          {...fontProps}
          text={country.name}
          color={hovered ? "green" : "black"}
          occlude
        />
        <Image
          position={[0, 0, 1.01]}
          scale={2}
          url={country.flag}
          visibile={true}
        />
      </group>
    </group>
  );
}

function Cloud({ count, radius, virtualScene, virtualCam, countries }) {
  // Create a count x count random words with spherical distribution
  const words = useMemo(() => {
    const temp = [];
    const spherical = new THREE.Spherical();
    const phiSpan = Math.PI / count;
    const thetaSpan = (Math.PI * 2) / count;
    let ind = 0;
    for (let i = 1; i < count - 1; i++) {
      for (let j = 0; j < count; j++) {
        if (ind < countries.length) {
          temp.push([
            new THREE.Vector3().setFromSpherical(
              spherical.set(radius, phiSpan * i, thetaSpan * j)
            ),
            countries[ind],
          ]);
          ind += 1;
        }
      }
    }
    return temp;
  }, [count, radius, countries]);

  return words.map(([pos, country], ind) => (
    <Country
      key={ind}
      position={pos}
      country={country}
      virtualScene={virtualScene}
      virtualCam={virtualCam}
    />
  ));
}

export default function CountrySphere({ virtualScene, virtualCam }) {
  const [input, setInput] = useState("");
  const [countryListDefault, setCountryListDefault] = useState();
  const [countryList, setCountryList] = useState();
  const [loaded, setLoaded] = useState(false);
  const fetchData = async () => {
    console.log("fetched");
    return await fetch("https://restcountries.com/v2/all")
      .then((response) => response.json())
      .then((data) => {
        setCountryList(data);
        setCountryListDefault(data);
        setLoaded(true);
      });
  };
  const updateInput = async (input) => {
    setInput(input);
    const filtered = countryListDefault.filter((country) => {
      return country.name.toLowerCase().includes(input.toLowerCase());
    });
    setCountryList(filtered);
  };
  // console.log(countryList);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Html center position={[0, -30, 0]}>
        <div className="flex w-80 bg-white rounded-lg m-1 p-1">
          <input
            className="flex-grow"
            value={input}
            placeholder={"search country"}
            onChange={(e) => setInput(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                console.log(e);
                updateInput(input);
              }
            }}
          />
          <button
            className="bg-blue-500 rounded-lg p-1 m-1"
            onClick={(e) => updateInput(input)}
          >
            Search
          </button>
        </div>
      </Html>
      {loaded ? (
        <Suspense fallback={null}>
          <ambientLight intensity={1} />
          <group position={[0, 0, 0]}>
            <Cloud
              count={Math.ceil(Math.sqrt(countryList.length) + 1)}
              radius={20 + countryList.length / 10}
              virtualScene={virtualScene}
              virtualCam={virtualCam}
              countries={countryList}
            />
          </group>
        </Suspense>
      ) : (
        <></>
      )}
      <OrbitControls camera={virtualCam.current} />
    </>
  );
}
